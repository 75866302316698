@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "dropzone/dist/dropzone.css";
@import 'tippy.js/dist/tippy.css';

body.wait, body.wait * {
  cursor: wait !important;
}

main {
  max-width: 38rem;
  padding: 2rem;
  margin: auto;
}

.danger {
  color: red;
}

.hide {
  display: none;
}

.field.error > .hide {
  display: block;
}

.field.error > span {
  display: block;
}

.field.error > input,
.field.error > textarea {
  background-color: rgba(255, 239, 213, 0.7);
}

.header-selected-tab {
  @apply font-bold;
  @apply border-2;
  @apply border-b-0;
  @apply bg-base-300;
  @apply link;
  @apply link-hover;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sort.asc::after {
  content: "\002B06";
  padding-left: 3px;
}

.sort.desc::after {
  content: "\002B07";
  padding-left: 3px
}

li.active {
  @apply border-b;
}
